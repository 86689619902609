import { ElectronIpcEvent, IpcEventType } from 'src/common/constants/events';
import * as rpcMethods from 'src/common/utils/rpc/workerRpcMethods';

export const handleMainProcessRequest = (ipcMain) => {
  ipcMain.on(IpcEventType.MAIN_PROCESS_REQUEST, (event: any, data) => {
    const { method, type, ...rest } = data;

    if (type !== ElectronIpcEvent) {
      // runtime guard
      return;
    }

    // @ts-ignore: TypeScript doesn't realize this union of functions will always
    // result in a function that has this call signature.
    rpcMethods[method]({
      ctx: event.sender,
      ...rest,
    });
  });
};
