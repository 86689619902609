import { ipcFacade } from 'src/browser/apis/browserIpcApi';
import { initQueryCache } from 'src/browser/apis/browserQueryCache';
import { handleAddData } from 'src/common/utils/rpc/handleAddData';
import { handleMainProcessRequest } from 'src/common/utils/rpc/handleMainProcessRequest';
import { handleRemoveData } from 'src/common/utils/rpc/handleRemoveData';

initQueryCache(ipcFacade);
handleMainProcessRequest(ipcFacade);
handleAddData();
handleRemoveData();
