import { IpcEventType, WorkerRpcEvent } from 'src/common/constants/events';
import { formatHeadersForPostMessage } from 'src/common/utils/rpc/formatHeadersForPostMessage';
import {
  ResponseType,
  WorkerErrorResponse,
  WorkerPartialResponse,
  WorkerResponse,
  isErrorResponseLike,
  isPartialResponse,
} from 'src/common/utils/rpc/types';
import { safelyGet } from 'src/common/utils/safelyGet';

const streamingTriggerLimit = 20000; // number of bindings in result set needed to trigger streaming of bindings
const streamChunkSize = 3000; // number of bindings sent in each chunk when streaming

// TODO: This currently works ONLY for streaming back bindings as a result of
// query execution. It'd be nice to generalize this. To generalize, we probably
// want to make some changes in stardog.js (v2), because the Response object
// that results from the `fetch` calls is at least sometimes already a stream.
// I say "at least sometimes" because browser support for that is probably
// limited, outside of Chrome, so we need to be cautious there.
const streamBindings = (ctx, eventId, bindings, restOfResponse) => {
  const bindingsChunk = bindings.slice(0, streamChunkSize);
  const restBindings = bindings.slice(streamChunkSize);
  const isResponseComplete = restBindings.length < 1;

  ctx.send(IpcEventType.MAIN_PROCESS_REQUEST_RESULT, {
    eventId,
    isResponseComplete,
    type: WorkerRpcEvent,
    responseType: isResponseComplete
      ? ResponseType.COMPLETE
      : ResponseType.PART,
    partialResponse: {
      body: {
        head: restOfResponse?.body?.head,
        results: {
          bindings: bindingsChunk,
        },
      },
      headers: formatHeadersForPostMessage(restOfResponse).headers,
    },
  });

  if (!isResponseComplete) {
    // Queue the next chunk. Note that because we are in the main process, we
    // can't use `requestIdleCallback` or `requestAnimationFrame`.
    setTimeout(
      () => streamBindings(ctx, eventId, restBindings, restOfResponse),
      30
    );
  }
};

// Sends a message back to the worker, formatted correctly for RPC.
export const postBack = <T>(
  ctx: any,
  partialData:
    | Partial<WorkerResponse<T>>
    | Partial<WorkerPartialResponse>
    | Partial<WorkerErrorResponse>
) => {
  let dataToSend;
  const { eventId } = partialData;
  let responseType = ResponseType.DONE;

  if (isErrorResponseLike(partialData)) {
    responseType = ResponseType.ERROR;
    dataToSend = {
      error: partialData.error,
    };
  } else if (isPartialResponse(partialData)) {
    responseType = ResponseType.PARTIAL;
    dataToSend = {
      partialResponse: formatHeadersForPostMessage(partialData.partialResponse),
    };
  } else {
    dataToSend = {
      response: formatHeadersForPostMessage(partialData.response),
    };

    // Check for very large result sets; if present, stream them back rather than
    // sending all at once. (See TODO above for a caveat.)
    const bindings = safelyGet(dataToSend, [
      'response',
      'body',
      'results',
      'bindings',
    ]);
    if (bindings && bindings.length >= streamingTriggerLimit) {
      streamBindings(ctx, partialData.eventId, bindings, partialData.response);
      return;
    }
  }

  ctx.send(IpcEventType.MAIN_PROCESS_REQUEST_RESULT, {
    type: WorkerRpcEvent,
    eventId,
    responseType,
    isResponseComplete: true,
    ...dataToSend,
  });
};
