import { db } from 'stardog';

export const withTransaction =
  (databaseId, apiFunc, ...args) =>
  (conn) =>
    db.transaction.begin(conn, databaseId).then((res) => {
      if (res.ok) {
        const { transactionId: txId } = res;
        // In all but one case (ICV validateInTx), parameters are conn, database, txId, everything else
        return apiFunc(conn, databaseId, txId, ...args).then((res) => {
          if (res.ok) {
            return db.transaction.commit(conn, databaseId, txId);
          }
          // May 404 if server already rolled it back, but might as well be safe
          return db.transaction
            .rollback(conn, databaseId, txId)
            .then(() =>
              Promise.reject(
                res.body || { message: res.statusText || String(res.status) }
              )
            );
        });
      }
      return Promise.resolve(res);
    });
