import * as stardog from 'stardog';

import { IpcEventType } from 'src/common/constants/events';
import { getStardogConnection } from 'src/common/utils/connection/getStardogConnection';
import { formatHeadersForPostMessage } from 'src/common/utils/rpc/formatHeadersForPostMessage';
import {
  Bindings,
  CACHED_ROWS_TO_FETCH,
  ReturnData,
  StardogQueryMethodData,
} from 'src/common/utils/rpc/types';

interface FetchArgs {
  cacheKeySuffix?: string;
  id: string;
  numRows?: number;
  row: number;
}

export interface CachePayload {
  bindings: Bindings;
  cacheKeySuffix?: string;
  noteId: string;
  row: number;
}

const cache = {} as {
  [id: string]: {
    bindings: Bindings;
  };
};

export const addToCache = (id: string, bindings: Bindings) => {
  cache[id] = { bindings };
};

const invalidate = (id: string) => delete cache[id];

const fetchRow = ({ id, row, numRows }: FetchArgs) =>
  cache[id]
    ? cache[id].bindings.slice(row, row + (numRows || CACHED_ROWS_TO_FETCH))
    : null;

export const initQueryCache = (ipcMain) => {
  ipcMain.on(IpcEventType.INVALIDATE_BINDINGS_CACHE, (_, id) => invalidate(id));
  ipcMain.handle(
    IpcEventType.FETCH_BINDINGS_CACHE,
    async (event, args: FetchArgs) => {
      const bindings = fetchRow(args);
      if (!bindings) {
        throw new Error('could not retrieve bindings');
      }
      const payload: CachePayload = {
        bindings,
        cacheKeySuffix: args.cacheKeySuffix,
        noteId: args.id,
        row: args.row,
      };
      event.sender.send(IpcEventType.RECEIVED_BINDINGS_CACHE, payload);
    }
  );

  ipcMain.on(
    IpcEventType.EXECUTE_SELECT_QUERY,
    async (event, options: StardogQueryMethodData) => {
      const {
        query,
        queryId,
        db,
        namedGraphs,
        reasoning,
        schema,
        limit,
        timeout,
        noteId,
        connection,
      } = options;

      invalidate(noteId);

      const conn = getStardogConnection(connection);

      try {
        const options: any = {
          reasoning,
        };
        if (queryId) {
          options.id = queryId;
        } else {
          options.flushResponseHeaders = 'true';
        }
        if (namedGraphs && namedGraphs.length) {
          options['graph-uri'] = namedGraphs;
        }
        if (timeout) {
          options.timeout = timeout;
        }
        if (limit) {
          options.limit = limit;
        }
        if (reasoning && schema) {
          options.schema = schema;
        }
        const response = await stardog.query.execute(
          conn,
          db,
          query,
          undefined,
          options,
          // If the query id is already defined at the time of this call, we've
          // generated it Studio-side (for Stardog >= 7.7.2), so we do not need
          // to re-get/send the query id on response start.
          options.id
            ? undefined
            : {
                onResponseStart(res) {
                  event.sender.send(IpcEventType.QUERY_RESPONSE_START, {
                    noteId,
                    queryId: res.headers.get('sd-query-id'),
                  });
                  return true;
                },
              }
        );

        if (!response.ok) {
          // Platform puts error messages on `response.body.message`
          throw response.body;
        }

        const { headers } = formatHeadersForPostMessage(response);
        const bindings: Bindings = response?.body?.results?.bindings || [];

        addToCache(noteId, bindings);

        const initalBindings = bindings.slice(0, CACHED_ROWS_TO_FETCH);

        const data: ReturnData = {
          ...response,
          noteId,
          body: {
            head: response?.body?.head,
            results: {
              totalLength: bindings.length,
              bindings: initalBindings,
            },
          },
          headers,
        };

        event.sender.send(IpcEventType.QUERY_RESULTS, data);
      } catch (e) {
        event.sender.send(IpcEventType.QUERY_FAILURE, {
          noteId,
          error: e ? e.message : undefined,
        });
      }
    }
  );
};
